<template>
    <v-timeline align-top>
        <template v-for="(item, index) in items">
            <v-timeline-item
                small
                :key="index"
                :class="item.class"
                :color="color"
                ><span v-html="item.title"></span>
            </v-timeline-item>
        </template>
    </v-timeline>
</template>
<script>
export default {
    name: "rental-employment-activity",
    components: {},
    props: {
        color: {
            type: String,
            required: false,
            default: "primary",
        },
        items: {
            type: Array,
            required: false,
            default: () => {
                return [
                    {
                        title:
                            "1 hour ago - <strong>Reference Received</strong>",
                        class: "",
                    },
                    {
                        title: "1 day ago - <strong>Reference Sent</strong>",
                        class: "text-right",
                    },
                    {
                        title: "2 days ago - <strong>Reference Sent</strong>",
                        class: "",
                    },
                    {
                        title: "3 days ago - <strong>Reference Sent</strong>",
                        class: "text-right",
                    },
                ];
            },
        },
    },
    data() {
        return {};
    },
};
</script>

<style scoped>
</style>