var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-timeline",
    { attrs: { "align-top": "" } },
    [
      _vm._l(_vm.items, function(item, index) {
        return [
          _c(
            "v-timeline-item",
            {
              key: index,
              class: item.class,
              attrs: { small: "", color: _vm.color }
            },
            [_c("span", { domProps: { innerHTML: _vm._s(item.title) } })]
          )
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }